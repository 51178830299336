import { lazy } from "react";
import Loadable from "../ui-component/Loadable";

const Login = Loadable(lazy(() => import("../views/pages/Login")));
const Dashboard = Loadable(lazy(() => import("../views/dashboard")));
const Notices = Loadable(lazy(() => import("../views/pages/Notices")));
const Users = Loadable(lazy(() => import("../views/pages/Users")));
const Profile = Loadable(lazy(() => import("../views/pages/Profile")));
const Page404 = Loadable(lazy(() => import("../views/errors/Page404")));

const routes = [
  {
    path: "/login",
    exact: true,
    listRoles: ["ADMIN", "USER"],
    component: Login,
    isPrivate: false,
    name: "Reclamos",
  },
  {
    path: "/",
    exact: true,
    listRoles: ["ADMIN", "USER"],
    component: Dashboard,
    isPrivate: true,
    name: "dashboard",
  },
  {
    path: "/noticias",
    exact: true,
    listRoles: ["ADMIN", "USER"],
    component: Notices,
    isPrivate: true,
    name: "noticias",
  },
  {
    path: "/usuarios",
    exact: true,
    listRoles: ["ADMIN"],
    component: Users,
    isPrivate: true,
    name: "usuarios",
  },
  {
    path: "/perfil",
    exact: true,
    listRoles: ["ADMIN", "USER"],
    component: Profile,
    isPrivate: true,
    name: "perfil",
  },
  {
    path: "",
    exact: true,
    listRoles: ["ADMIN", "USER"],
    component: Page404,
    isPrivate: true,
    name: "",
  },
];

export default routes;
