import api from "../store/api";
import SessionStorageService from "./SessionStorageService";
import SessionTimeService from "./SessionTimeService";
import SESSION_NAME from "../store/session";
import { log } from "util";

class AuthenticationService {
  static isAuthenticated = false;

  static userData;

  static headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };

  static login(obj) {
    return new Promise((resolve, reject) => {
      const requestUrl = `${api}/auth/login`;
      const requestData = {
        user: obj.username,
        password: obj.password,
      };
      fetch(requestUrl, {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: this.headers,
      })
        .then((response) => {
          if (response.ok) {
            var data = response.json();
            data.then((res) => {
              this.isAuthenticated = true;
              this.userData = res;
              SessionStorageService.set(SESSION_NAME, {
                expiresAt: SessionTimeService.estimatedTime(),
                value: res,
              });
              resolve(res);
            });
          } else {
            reject(response);
          }
        })
        .catch((error) => reject(error));
    });
  }

  static isUserAuthenticated() {
    const userData = SessionStorageService.get(SESSION_NAME);
    this.isAuthenticated =
      !!userData && typeof userData.value.api_token === "string";
    //Authenticated api token
    const requestUrl = `${api}/users/token`;
    if (this.isAuthenticated) {
      fetch(requestUrl, {
        method: "POST",
        body: JSON.stringify({ token: userData.value.api_token }),
        headers: this.headers,
      })
        .then((response) => {
          if (response.ok) {
            var data = response.json();
            data.then((res) => {
              this.isAuthenticated = this.isAuthenticated ? res : false;
            });
          }
        })
        .catch((error) => log(error));
    }
    return this.isAuthenticated;
  }

  static logout() {
    return new Promise((resolve, reject) => {
      this.isAuthenticated = false;
      this.userData = null;
      SessionStorageService.remove(SESSION_NAME);
      resolve();
    });
  }
}

export default AuthenticationService;
