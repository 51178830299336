// assets
import { IconBrandChrome, IconHelp, IconSitemap } from "@tabler/icons";

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

const menuItems = {
  id: "sample-docs-roadmap",
  type: "group",
  title: "NAVEGACIÓN",
  children: [
    {
      id: "dashboard",
      title: "DASHBOARD",
      type: "item",
      url: "/",
      icon: IconBrandChrome,
      breadcrumbs: false,
    },
    {
      id: "noticias",
      title: "NOTICIAS",
      type: "item",
      url: "/noticias",
      icon: IconHelp,
      breadcrumbs: true,
    },
    {
      id: "usuarios",
      title: "USUARIOS",
      type: "item",
      url: "/usuarios",
      icon: IconSitemap,
      breadcrumbs: true,
    },
    /* {
      id: "icons",
      title: "Icons",
      type: "collapse",
      icon: icons["IconBrandChrome"],
      children: [
        {
          id: "tabler-icons",
          title: "Tabler Icons",
          type: "item",
          url: "/icons/tabler-icons",
          breadcrumbs: false,
        },
        {
          id: "material-icons",
          title: "Material Icons",
          type: "item",
          url: "/icons/material-icons",
          breadcrumbs: false,
        },
      ],
    }, */
  ],
};

export default menuItems;
