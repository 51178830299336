import React from "react";
import { Link } from "react-router-dom";

// material-ui
import { ButtonBase, Avatar } from "@material-ui/core";

// project imports
import user from "src/store/userDetails";

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
  return (
    <ButtonBase disableRipple component={Link} to={{ pathname: "/perfil" }}>
      <Avatar
        src={
          user
            ? user.data.photo_url
            : "https://filesdic.s3.us-east-2.amazonaws.com/imgSchool/logo-ym.png"
        }
        style={{ height: 50, width: 50 }}
        alt="profile picture"
      />
    </ButtonBase>
  );
};

export default LogoSection;
