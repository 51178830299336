import React from "react";
import { Typography, Box } from "@material-ui/core";

const Page404 = () => {
  return (
    <Box pt={10}>
      <Typography align="center" color="textSecondary" variant="h3">
        <h1>401</h1>
        NO ESTÁS AUTORIZADO
      </Typography>
    </Box>
  );
};

export default Page404;
