import PropTypes from "prop-types";
import React from "react";

// material-ui
import { makeStyles } from "@material-ui/styles";
import {
  Divider,
  List,
  Typography,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// project imports
import NavItem from "./../NavItem";
import NavCollapse from "./../NavCollapse";

// auth
import AuthenticationService from "src/auth/AuthenticationService";

// style constant
const useStyles = makeStyles((theme) => ({
  menuCaption: {
    ...theme.typography.menuCaption,
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
  menuDivider: {
    marginTop: "2px",
    marginBottom: "10px",
  },
}));

//-----------------------|| SIDEBAR MENU LIST GROUP ||-----------------------//

const NavGroup = ({ item }) => {
  const classes = useStyles();

  // menu list collapse & items
  const items = item.children.map((menu) => {
    switch (menu.type) {
      case "collapse":
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case "item":
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Logout = () => {
    AuthenticationService.logout().then(() => {
      window.location.href = "/login";
    });
  };

  return (
    <React.Fragment>
      <List
        subheader={
          item.title && (
            <Typography
              variant="caption"
              className={classes.menuCaption}
              display="block"
              gutterBottom
            >
              {item.title}
              {item.caption && (
                <Typography
                  variant="caption"
                  className={classes.subMenuCaption}
                  display="block"
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider className={classes.menuDivider} />
      <List>
        <ListItem button onClick={Logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          CERRAR SESIÓN
        </ListItem>
      </List>
    </React.Fragment>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
