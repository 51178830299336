// assets
import { IconBrandChrome, IconHelp, IconSitemap } from "@tabler/icons";

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

const menuItems = {
  id: "sample-docs-roadmap",
  type: "group",
  title: "USUARIO",
  children: [
    {
      id: "dashboard",
      title: "DASHBOARD",
      type: "item",
      url: "/",
      icon: IconBrandChrome,
      breadcrumbs: false,
    },
    {
      id: "noticias",
      title: "NOTICIAS",
      type: "item",
      url: "/noticias",
      icon: IconHelp,
      breadcrumbs: true,
    },
    {
      id: "leyes",
      title: "LEYES",
      type: "item",
      url: "/leyes",
      icon: IconHelp,
      breadcrumbs: true,
    },
    {
      id: "blog",
      title: "BLOG",
      type: "item",
      url: "/blog",
      icon: IconHelp,
      breadcrumbs: true,
    },
    {
      id: "etiquetas",
      title: "ETIQUETAS",
      type: "item",
      url: "/etiquetas",
      icon: IconHelp,
      breadcrumbs: true,
    },
    {
      id: "constantes",
      title: "CONSTANTES",
      type: "item",
      url: "/constantes",
      icon: IconSitemap,
      breadcrumbs: true,
    },
  ],
};

export default menuItems;
