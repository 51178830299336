import admin from "./admin";
import user from "./user";
import userDetails from "src/store/userDetails";

let list = {};

switch (userDetails.rol) {
  case "ADMIN":
    list = admin;
    break;

  case "USER":
    list = user;
    break;

  default:
    list = {};
    break;
}

let menuItems = { items: [admin] };

export default menuItems;
