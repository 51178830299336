import React from "react";
import { useSelector } from "react-redux";

import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, StyledEngineProvider } from "@material-ui/core";

// routing
import routes from "./routes";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import AppRoute from "./layout/AppRoute";

// defaultTheme
import theme from "./themes";

// project imports
import NavigationScroll from "./layout/NavigationScroll";

//-----------------------|| APP ||-----------------------//

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Router>
            <Switch>
              {routes.map((route) => (
                <AppRoute
                  exact={route.exact}
                  key={route.path}
                  path={route.path}
                  listRoles={route.listRoles}
                  component={route.component}
                  isPrivate={route.isPrivate}
                />
              ))}
            </Switch>
          </Router>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
