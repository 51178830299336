import SESION_NAME from "./session";
import SessionStorageService from "../auth/SessionStorageService";

let all = SessionStorageService.get(SESION_NAME);

let data = all ? all.value.data : null;
let name = all ? all.value.data.name : null;
let token = all ? all.value.token : null;
let userid = all ? all.value.data._id : null;
let rol = "ADMIN";

const userDetails = { token, data, name, userid, rol };

export default userDetails;
